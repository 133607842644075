body {
  font-family: -apple-system, "Helvetica Neue", "Lucida Grande";
}
h1 {
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-h1);
}

h2 {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-h2);
}

h3 {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-h3);
}

p {
  font-size: var(--font-size-body1);
  font-weight: var(--font-weight-body1);
}

:root {
  --color-interactive: #9e003a;
  --color-interactive-contrast: #ffffff;

  --color-background: #ffffff;
  --color-background-complimentary: #f4f4f4;
  --color-background-placeholder: #f1f1f1;
  --color-background-highlighted: #fafafa;
  --color-background-contrast: #000000;
  --color-brand-primary: #5b2020;
  --color-brand-secondary: #b70040;
  --color-divider: #e8e8e8;
  --color-outline: #e0e0e0;
  --color-outline-highlighted: #000000;
  --color-warning: #ffae00;
  --color-error: #ff0000;

  --color-text-primary: #686868;
  --color-text-secondary: #8f8f8f;
  --color-text-disabled: #a5a5a5;
  --color-text-placeholder: #a5a5a5;

  --font-size-h1: "1rem";
  --font-weight-h1: 600;

  --font-size-h2: "3rem";
  --font-weight-h2: 200;

  --font-size-h3: "1.125rem";
  --font-weight-h3: 400;

  --font-size-subtitle: "1rem";
  --font-weight-subtitle: 500;

  --font-size-subtitle2: "0.875rem";
  --font-weight-subtitle2: 500;

  --font-size-body1: "1rem";
  --font-weight-body1: 400;

  --font-size-body2: "0.9rem";
  --font-weight-body2: 300;

  --spacing-base-size: 16px;
  --padding-base-size: 16px;

  --animation-base-timing: 0.36s;
}

.dropdown {
  position: absolute;
  z-index: 1000;
  right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  border: 1px solid var(--color-outline);
  border-radius: 2px;
}

.hidden {
  display: none;
}

.container {
  width: 100%;
  min-height: 50px;
  background-color: var(--color-background);
  border: 1px solid white;
  border-radius: 2px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  transform: scale(1);
  transition: transform 0.2s ease;
}

.container:active {
  background-color: var(--color-background-highlighted);
}
.container:hover {
  border: 1px solid var(--color-outline);
}

.icon {
  width: 30px;
  height: 30px;
}

.title {
  font-size: 16px;
  color: var(--color-text-primary);
}

.titleBar {
  height: 120px;
  background-color: white;
  color: var(--color-text-primary);
  font-size: 18px;
  font-weight: 200;
  padding-inline: 8px;
}

.titleBarContent {
  min-height: 120px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.titleBarLeftContent {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-left: 50px;
}

.titleBarCenterContent {
  width: 20%;
  min-width: 177px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleBarRightContent {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.title {
  font-weight: 300;
  color: var(--color-text-primary);
}

.titleBarButton {
  background-color: white;
  border: none;
  width: 50px;
  height: 50px;
  color: inherit;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.2s ease;
}

.titleBarButton:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease;
}

.titleBarButtonImage {
  width: 20px;
  height: 20px;
}

.button {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 50px;
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 3px;
  font-weight: 400px;
  padding-inline: 32px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease, color 0.2s ease; /* Add transition for smooth animation */
}

.ctaPrimary {
  background-color: var(--color-interactive);
  color: var(--color-interactive-contrast);
  border: 3px solid var(--color-interactive);
}

.ctaPrimary:active {
  background-color: var(--color-background);
  color: var(--color-interactive);
  border: 3px solid var(--color-interactive);
  transform: scale(0.97);
}

.icon {
  width: 20px;
  height: 20px;
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg)
    brightness(119%) contrast(119%);
}

.ctaSecondary {
  background-color: var(--color-background);
  color: var(--color-interactive);
  border: 3px solid var(--color-interactive);
}

.ctaSecondary:active {
  background-color: var(--color-interactive);
  color: var(--color-background);
  border: 3px solid var(--color-interactive);
}

.ctaLink {
  min-height: 20px;
  height: 20px;
  padding-inline: 4px;
  font-weight: 600;
  background-color: var(--color-background);
  color: var(--color-interactive);
  border: none;
}

.ctaLink:active {
  height: 20px;
  background-color: var(--color-interactive);
  color: var(--color-interactive-contrast);
  border: none;
}
